<template>
  <div id="main-content" class="main-content-normal" :class="{ 'main-content-bigger': $store.state.mainBig }">
    <div class="container-fluid">
      <div class="block-header">
        <div class="row clearfix">
          <div class="col-lg-4 col-md-12 col-sm-12">
            <h1>
              <!-- 报表中心 -->
              {{$t('sidebar.menu.2133314RMTX7WB4E')}}
            </h1>
          </div>
        </div>
      </div>
      <div class="row clearfix mt-4">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="row clearfix">
                <div class="col-lg-12 col-lg-auto">
                  <div class="row clearfix">
                    <div class="col-md-8 col-8-auto">
                      <el-row type="flex" align="middle">
                        <el-row type="flex" align="middle" class="mr-3">
                          <el-select @change="reportTypeChange()" v-model="reportType" style="max-width:150px">
                            <el-option key="1" value="1" :label="$t('ReportDownloads.psReport')"></el-option>
                            <el-option key="2" value="2" :label="$t('ReportDownloads.deviceReport')"></el-option>
                          </el-select>
                        </el-row>
                        <select-ps-id-view @psIdChange="handlePsIdChange" style="width:400px" />
                        <el-row class="ml-3" style="width:200px;">
                          <el-tooltip class="item" :content="$t('charts.chartsxzrb')" placement="bottom" popper-class="dark_aooltip">
                            <el-button :type="primaryArr.days.primaryDay" @click="chageTypeCalendar" circle v-html="$t('history.DatePicker.ChooseDay')"></el-button>
                          </el-tooltip>
                          <el-tooltip class="item " :content="$t('charts.chartsxzyb')" placement="bottom" popper-class="dark_aooltip">
                            <el-button :type="primaryArr.month.primaryMonth" @click="chageTypeCalendar" circle v-html="$t('history.DatePicker.ChooseMonth')"></el-button>
                          </el-tooltip>
                        </el-row>
                      </el-row>
                    </div>

                    <!-- :element-loading-text="$t('charts.chartsLoading')" -->

                    <div class="col-md-4 col-4-auto mt-1" v-if="reportType == '2'">
                      <el-row type="flex" justify="end" align="middle">
                        <el-button v-for="item in deviceTypeList" :key="item.deviceTypeId" type="aurora" size="small" :icon="deviceTypeIcon[item['deviceTypeId']]" @click="changeDeviceTypeId(item['deviceTypeId'])" class="divElButton" :class="{
                          'is-active gradient':
                            item['deviceTypeId'] == deviceTypeId
                        }" round>
                          <span class="ml-1">{{
                          $t(deviceTypeName[item["deviceTypeId"]])
                        }}</span>
                        </el-button>
                      </el-row>
                    </div>

                    <!-- <div>
                      <div class="">
                        <el-select @change="reportTypeChange()" v-model="reportType">
                          <el-option key="1" value="1" :label="$t('ReportDownloads.psReport')"></el-option>
                          <el-option key="2" value="2" :label="$t('ReportDownloads.deviceReport')"></el-option>
                        </el-select>
                      </div>
                    </div>

                    <div class="">
                      <select-ps-id-view :cls="'col-12'" @psIdChange="handlePsIdChange" />
                    </div>

                    <el-col class="">
                      <el-tooltip class="item" :content="$t('charts.chartsxzrb')" placement="bottom" popper-class="dark_aooltip">
                        <el-button :type="primaryArr.days.primaryDay" @click="chageTypeCalendar" circle>日</el-button>
                      </el-tooltip>
                      <el-tooltip class="item " :content="$t('charts.chartsxzyb')" placement="bottom" popper-class="dark_aooltip">
                        <el-button :type="primaryArr.month.primaryMonth" @click="chageTypeCalendar" circle>月</el-button>
                      </el-tooltip>
                    </el-col> -->
                    <!-- :element-loading-text="$t('charts.chartsLoading')" -->
                    <!-- <div class="" v-if="reportType == '2'">
                      <el-button v-for="item in deviceTypeList" :key="item.deviceTypeId" type="aurora" size="small" :icon="deviceTypeIcon[item['deviceTypeId']]" @click="changeDeviceTypeId(item['deviceTypeId'])" class="divElButton" :class="{
                          'is-active gradient':
                            item['deviceTypeId'] == deviceTypeId
                        }" round>
                        <span class="ml-1">{{
                          $t(deviceTypeName[item["deviceTypeId"]])
                        }}</span>
                      </el-button>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>

            <div class="card-header">
              <div class="col-lg-12 col-lg-auto">
                <div class="row clearfix">
                  <div class="col-md-2 col-md-auto">
                    <el-date-picker v-model="valueson1" @change="dateSreach" :picker-options="pickerOptions" :clearable="false" :editable="false" :value-format="calendarArr.format" :type="calendarArr.type" :placeholder="$t('charts.changeTime')" />
                  </div>
                  <div class="col-md-10 col-md-auto m-b--5 text-right">
                    <div class="demo-input-suffix">
                      <el-button type="aurora" :loading="reportParam.loading" class="is-active" size="small" @click="downClick()">{{ $t("ReportDownloads.title") }}</el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectPsIdView from "../components/HistoryView/SelectPsId.vue";

export default {
  components: {
    SelectPsIdView
  },
  name: "report",

  mounted() {},

  data() {
    return {
      deviceModuleBms: 0,
      psName: "",
      reportType: "1",
      deviceTypeId: "",
      deviceTypeName: {
        "1": "deviceTypeGroup.pcs", //"PCS",//PCS
        "2": "deviceTypeGroup.dcdc", //"DCDC",//DCDC
        "3": "deviceTypeGroup.bms", //"蓄電池",//蓄電池
        "5": "deviceTypeGroup.ammeter", //"电表",//电表
        "4": "deviceTypeGroup.ari", //"空调",//空调
        "6": "deviceTypeGroup.fire" //"消防",//消防
      },
      deviceTypeIcon: {
        "1": "fad fa-archive", //PCS
        "2": "fad fa-tablet-rugged", //DCDC
        "3": "fad fa-car-battery", //蓄電池
        "5": "fad fa-tachometer-alt-fast", //电表
        "4": "fad fa-snow-blowing", //空调
        "6": "fad fa-fire-extinguisher" //消防
      },
      deviceTypeList: [],
      reportParam: {
        name: "",
        title: [],
        val: [],
        result: [],
        loading: false,
        isDis: true
      },
      reset: "",
      loadData: "",
      initSon: "",
      setParamsButton: true,
      callnum: "",
      calendarArr: { format: "yyyy-MM-dd", type: "date" },
      primaryArr: {
        days: {
          primaryDay: "primary"
        },
        month: {
          primaryMonth: ""
        }
      },
      sreachType: "",
      keyDatas: [],
      visibleValue: [],
      olddeviceTypeId: null,
      oldsearchdate: null,

      // lhl end
      loading: false,
      otimer: null,
      isload: false,
      st: { opacity: 1 },
      options: [],
      optionsPara: [],
      valueson1: "",
      value1: [],
      value2: [],
      pickerOptions: {
        disabledDate(time) {
          let buildDate = window.sessionStorage.getItem("buildDate");
          console.log(buildDate);
          let dts = buildDate.split("-");
          let dt = new Date(
            parseInt(dts[0]),
            parseInt(dts[1]) - 1,
            parseInt(dts[2]) - 1
          );
          return time.getTime() >= Date.now() || time.getTime() <= dt.getTime();
        }
      }
    };
  },
  watch: {
    "$store.state.languageId": function() {
      if (this.checkDeviceTypeId.indexOf("_") >= 0) {
        this.checkDeviceTypeId =
          this.checkDeviceTypeId.split("_")[0] +
          "_" +
          Math.floor(Math.random() * 999999);
      }
    }
  },
  mounted() {
    this.callnum = Math.floor(Math.random() * 999999);
    this.showLoading = false;
    this.oldsearchdate = this.formatDate(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      new Date().getDate()
    );
    this.valueson1 = this.oldsearchdate;
  },
  methods: {
    addBmsPara() {
      var _this = this;
      var deviceModule = _this.deviceModuleBms;
      var mtMaxList = [];
      var mtMinList = [];
      var mvMaxList = [];
      var mvMinList = [];
      var msocList = [];
      var mtMaxListVal = [];
      var mtMinListVal = [];
      var mvMaxListVal = [];
      var mvMinListVal = [];
      var msocListVal = [];
      var vpvsVal = [];
      for (var i = 1; i <= deviceModule; i++) {
        mtMaxList.push("RACK" + i + this.$t("ReportDownloads.device.zgwd"));
        mtMinList.push("RACK" + i + this.$t("ReportDownloads.device.zdwd"));
        mvMaxList.push("RACK" + i + this.$t("ReportDownloads.device.zgdy"));
        mvMinList.push("RACK" + i + this.$t("ReportDownloads.device.zddy"));
        msocList.push("RACK" + i + "Soc(%)");

        mtMaxListVal.push("mtMaxList-" + (i - 1));
        mtMinListVal.push("mtMinList-" + (i - 1));
        mvMaxListVal.push("mvMaxList-" + (i - 1));
        mvMinListVal.push("mvMinList-" + (i - 1));
        msocListVal.push("msocList-" + (i - 1));
      }
      _this.reportParam.title = _this.reportParam.title.concat(mtMaxList);
      _this.reportParam.title = _this.reportParam.title.concat(mtMinList);
      _this.reportParam.title = _this.reportParam.title.concat(mvMaxList);
      _this.reportParam.title = _this.reportParam.title.concat(mvMinList);
      _this.reportParam.title = _this.reportParam.title.concat(msocList);
      _this.reportParam.val = _this.reportParam.val.concat(mtMaxListVal);
      _this.reportParam.val = _this.reportParam.val.concat(mtMinListVal);
      _this.reportParam.val = _this.reportParam.val.concat(mvMaxListVal);
      _this.reportParam.val = _this.reportParam.val.concat(mvMinListVal);
      _this.reportParam.val = _this.reportParam.val.concat(msocListVal);
    },
    reportTypeChange() {},
    formatReportPs(result) {
      var _this = this;
      _this.reportParam = {
        name: "",
        title: [],
        val: [],
        result: [],
        loading: false,
        isDis: true
      };
      _this.reportParam.result = result;
      if (_this.calendarArr.type == "date") {
        _this.reportParam.name =
          this.psName + this.valueson1 + this.$moment().format(" HHmmss");
        switch (_this.$store.state.psTypeId) {
          case 3:
            //削峰（AC）
            _this.reportParam.title = [
              this.$t("ReportDownloads.ps.rq"),
              this.$t("ReportDownloads.ps.cfdgl"),
              this.$t("ReportDownloads.ps.xfgl"),
              "SOC（%）"
            ];
            _this.reportParam.val = ["getdatetime", "pac", "powerCon", "soc"];

            break;
          case 2:
            //过积载
            _this.reportParam.title = [
              this.$t("ReportDownloads.ps.rq"),
              this.$t("ReportDownloads.ps.gffdgl"),
              this.$t("ReportDownloads.ps.cfdgl"),
              this.$t("ReportDownloads.ps.soc")
            ];
            _this.reportParam.val = ["getdatetime", "pvPac", "pac", "soc"];

            break;
          case 1:
            //自家消费
            _this.reportParam.title = [
              this.$t("ReportDownloads.ps.rq"),
              this.$t("ReportDownloads.ps.gffdgl"),
              this.$t("ReportDownloads.ps.cfdgl"),
              this.$t("ReportDownloads.ps.yhdbgl"),
              this.$t("ReportDownloads.ps.xfgl"),
              this.$t("ReportDownloads.ps.soc")
            ];
            _this.reportParam.val = [
              "getdatetime",
              "pvPac",
              "pac",
              "gridPac",
              "powerCon",
              "soc"
            ];

            break;
          default:
            _this.reportParam.isDis = false;
            break;
        }
      } else if (_this.calendarArr.type == "month") {
        _this.reportParam.name =
          this.psName +
          this.$moment(this.valueson1).format("YYYY-MM") +
          this.$moment().format(" HHmmss");
        switch (_this.$store.state.psTypeId) {
          case 3:
            //削峰（AC）
            _this.reportParam.title = [
              this.$t("ReportDownloads.ps.rq"),
              this.$t("ReportDownloads.ps.cdl"),
              this.$t("ReportDownloads.ps.fdl"),
              this.$t("ReportDownloads.ps.xfdl")
            ];
            _this.reportParam.val = [
              "getdatetime",
              "inTotal",
              "outTotal",
              "totalpac"
            ];

            break;
          case 2:
            //过积载
            _this.reportParam.title = [
              this.$t("ReportDownloads.ps.rq"),
              this.$t("ReportDownloads.ps.gffdl"),
              this.$t("ReportDownloads.ps.mdl")
            ];
            _this.reportParam.val = ["getdatetime", "pvTotal", "totalpac"];

            break;
          case 1:
            //自家消费
            _this.reportParam.title = [
              this.$t("ReportDownloads.ps.rq"),
              this.$t("ReportDownloads.ps.mdl2"),
              this.$t("ReportDownloads.ps.mdl"),
              this.$t("ReportDownloads.ps.cdl"),
              this.$t("ReportDownloads.ps.fdl"),
              this.$t("ReportDownloads.ps.gffdl"),
              this.$t("ReportDownloads.ps.xfdl")
            ];
            _this.reportParam.val = [
              "getdatetime",
              "gridEngery",
              "sendEngery",
              "inTotal",
              "outTotal",
              "pvTotal",
              "totalpac"
            ];

            break;
          default:
            _this.reportParam.isDis = false;
            break;
        }
      }
    },
    formatReportDevice(result) {
      var _this = this;
      var deviceModule = 0;
      _this.reportParam = {
        name: "",
        title: [],
        val: [],
        result: [],
        loading: false,
        isDis: true
      };
      $(result).each(function(i, val) {
        deviceModule =
          val.deviceModule > deviceModule ? val.deviceModule : deviceModule;
        _this.deviceModuleBms = deviceModule;
        var deviceData = val.datas;
        $(deviceData).each(function(x, dData) {
          dData.deviceName = val.deviceName;
          _this.reportParam.result.push(dData);
        });
      });
      if (_this.calendarArr.type == "date") {
        //1.pcs  2.dcdc  3.Bms   4.空调   5.电表
        if (_this.$store.state.psTypeId == 1) {
          //自家消费
          switch (_this.deviceTypeId) {
            case 1:
              //pcs  修改为数据获取设备id ，module
              var deviceInfo = JSON.parse(
                window.sessionStorage.getItem("deviceInfo")
              );
              _this.reportParam.title = [
                this.$t("ReportDownloads.getdatetime"),
                this.$t("ReportDownloads.deviceName"),
                this.$t("ReportDownloads.device.jldl2") + "A(A)",
                this.$t("ReportDownloads.device.jldl2") + "B(A)",
                this.$t("ReportDownloads.device.jldl2") + "C(A)",
                this.$t("ReportDownloads.device.jldy2") + "A(V)",
                this.$t("ReportDownloads.device.jldy2") + "B(V)",
                this.$t("ReportDownloads.device.jldy2") + "C(V)",
                this.$t("ReportDownloads.device.jlgl"),
                this.$t("ReportDownloads.device.jlpl"),
                this.$t("ReportDownloads.device.glmkwd"),
                this.$t("ReportDownloads.device.jgwd"),
                this.$t("ReportDownloads.device.jrfd"),
                this.$t("ReportDownloads.device.ljfd"),
                this.$t("ReportDownloads.device.jrcd"),
                this.$t("ReportDownloads.device.ljcd"),
                this.$t("ReportDownloads.device.jrfd2"),
                this.$t("ReportDownloads.device.ljfd2")
              ];
              _this.reportParam.val = [
                "getdatetime",
                "deviceName",
                "rIac",
                "sIac",
                "tIac",
                "rVac",
                "sVac",
                "tVac",
                "pac",
                "rFac",
                "itemp",
                "temp",
                "dayPac",
                "totalPac",
                "dayInTotal",
                "inTotal",
                "dayOutTotal",
                "outTotal"
              ];

              var ipvs = [];
              var ppvs = [];
              var vpvs = [];
              var ipvsVal = [];
              var ppvsVal = [];
              var vpvsVal = [];
              for (var i = 1; i <= deviceModule; i++) {
                ipvs.push(
                  this.$t("ReportDownloads.device.zlglpv") + i + "（kW）"
                );
                ppvs.push(
                  this.$t("ReportDownloads.device.zldlpv") + i + "（A）"
                );
                vpvs.push(
                  this.$t("ReportDownloads.device.zldypv") + i + "（V）"
                );
                ipvsVal.push("ppvs-" + (i - 1));
                ppvsVal.push("ipvs-" + (i - 1));
                vpvsVal.push("vpvs-" + (i - 1));
              }
              _this.reportParam.title = _this.reportParam.title.concat(ipvs);
              _this.reportParam.title = _this.reportParam.title.concat(ppvs);
              _this.reportParam.title = _this.reportParam.title.concat(vpvs);

              _this.reportParam.val = _this.reportParam.val.concat(ipvsVal);
              _this.reportParam.val = _this.reportParam.val.concat(ppvsVal);
              _this.reportParam.val = _this.reportParam.val.concat(vpvsVal);

              _this.reportParam.name =
                "PCS--" +
                this.psName +
                this.$moment(this.valueson1).format("YYYY-MM-DD") +
                this.$moment().format(" HHmmss");
              break;
            case 2:
              //dcdc
              _this.reportParam.title = [
                this.$t("ReportDownloads.getdatetime"),
                this.$t("ReportDownloads.deviceName"),
                this.$t("ReportDownloads.device.zt"),
                this.$t("ReportDownloads.device.gl"),
                this.$t("ReportDownloads.device.dl"),
                this.$t("ReportDownloads.device.dy"),
                this.$t("ReportDownloads.device.glmkwd"),
                this.$t("ReportDownloads.device.jgwd")
              ];
              _this.reportParam.val = [
                "getdatetime",
                "deviceName",
                "deviceFlag",
                "ppv",
                "ipv",
                "vpv",
                "itemp",
                "temp"
              ];
              _this.reportParam.name =
                "DCDC--" +
                this.psName +
                this.$moment(this.valueson1).format("YYYY-MM-DD") +
                this.$moment().format(" HHmmss");
              break;
            case 3:
              //Bms
              _this.reportParam.title = [
                this.$t("ReportDownloads.getdatetime"),
                this.$t("ReportDownloads.deviceName"),
                this.$t("ReportDownloads.device.dy"),
                this.$t("ReportDownloads.device.cfddl"),
                this.$t("ReportDownloads.device.cfdgl"),
                "SOC（%）"
              ];
              _this.reportParam.val = [
                "getdatetime",
                "deviceName",
                "bv",
                "bcc",
                "bcp",
                "soc"
              ];
              _this.addBmsPara();
              _this.reportParam.name =
                "BMS--" +
                this.psName +
                this.$moment(this.valueson1).format("YYYY-MM-DD") +
                this.$moment().format(" HHmmss");
              break;
            case 4:
              //空调
              _this.reportParam.title = [
                this.$t("ReportDownloads.getdatetime"),
                this.$t("ReportDownloads.deviceName"),
                this.$t("ReportDownloads.device.zt"),
                this.$t("ReportDownloads.device.snwd")
              ];
              _this.reportParam.val = [
                "getdatetime",
                "deviceName",
                "deviceFlag",
                "indoortemp"
              ];
              _this.reportParam.name =
                this.$t("ReportDownloads.device.kt") +
                this.psName +
                this.$moment(this.valueson1).format("YYYY-MM-DD") +
                this.$moment().format(" HHmmss");
              break;
            case 5:
              //电表
              _this.reportParam.title = [
                this.$t("ReportDownloads.getdatetime"),
                this.$t("ReportDownloads.deviceName"),
                this.$t("ReportDownloads.device.gl")
              ];
              _this.reportParam.val = ["getdatetime", "deviceName", "pac"];
              _this.reportParam.name =
                this.$t("ReportDownloads.device.db") +
                this.psName +
                this.$moment(this.valueson1).format("YYYY-MM-DD") +
                this.$moment().format(" HHmmss");
              break;
            default:
              _this.reportParam.isDis = false;
              break;
          }
        } else if (_this.$store.state.psTypeId == 2) {
          //过激载
          switch (_this.deviceTypeId) {
            case 1:
              //pcs
              var deviceInfo = JSON.parse(
                window.sessionStorage.getItem("deviceInfo")
              );
              var deviceModule = _this.deviceModuleBms;
              _this.reportParam.title = [
                this.$t("ReportDownloads.getdatetime"),
                this.$t("ReportDownloads.deviceName"),
                this.$t("ReportDownloads.device.jldl2") + "A(A)",
                this.$t("ReportDownloads.device.jldl2") + "B(A)",
                this.$t("ReportDownloads.device.jldl2") + "C(A)",
                this.$t("ReportDownloads.device.jldy2") + "A(V)",
                this.$t("ReportDownloads.device.jldy2") + "B(V)",
                this.$t("ReportDownloads.device.jldy2") + "C(V)",
                this.$t("ReportDownloads.device.jlgl"),
                this.$t("ReportDownloads.device.jlpl"),
                this.$t("ReportDownloads.device.glmkwd"),
                this.$t("ReportDownloads.device.jgwd"),
                this.$t("ReportDownloads.device.jrfd"),
                this.$t("ReportDownloads.device.ljfd"),
                this.$t("ReportDownloads.device.jrcd"),
                this.$t("ReportDownloads.device.ljcd"),
                this.$t("ReportDownloads.device.jrfd2"),
                this.$t("ReportDownloads.device.ljfd2")
              ];
              _this.reportParam.val = [
                "getdatetime",
                "deviceName",
                "rIac",
                "sIac",
                "tIac",
                "rVac",
                "sVac",
                "tVac",
                "pac",
                "rFac",
                "itemp",
                "temp",
                "dayPac",
                "totalPac",
                "dayInTotal",
                "inTotal",
                "dayOutTotal",
                "outTotal"
              ];
              var ipvs = [];
              var ppvs = [];
              var vpvs = [];
              var ipvsVal = [];
              var ppvsVal = [];
              var vpvsVal = [];
              for (var i = 1; i <= deviceModule; i++) {
                ipvs.push(
                  this.$t("ReportDownloads.device.zlglpv") + i + "（kW）"
                );
                ppvs.push(
                  this.$t("ReportDownloads.device.zldlpv") + i + "（A）"
                );
                vpvs.push(
                  this.$t("ReportDownloads.device.zldypv") + i + "（V）"
                );
                ipvsVal.push("ppvs-" + (i - 1));
                ppvsVal.push("ipvs-" + (i - 1));
                vpvsVal.push("vpvs-" + (i - 1));
              }
              _this.reportParam.title = _this.reportParam.title.concat(ipvs);
              _this.reportParam.title = _this.reportParam.title.concat(ppvs);
              _this.reportParam.title = _this.reportParam.title.concat(vpvs);

              _this.reportParam.val = _this.reportParam.val.concat(ipvsVal);
              _this.reportParam.val = _this.reportParam.val.concat(ppvsVal);
              _this.reportParam.val = _this.reportParam.val.concat(vpvsVal);

              _this.reportParam.name =
                "PCS--" +
                this.psName +
                this.$moment(this.valueson1).format("YYYY-MM-DD") +
                this.$moment().format(" HHmmss");
              break;
            case 2:
              //dcdc
              _this.reportParam.title = [
                this.$t("ReportDownloads.getdatetime"),
                this.$t("ReportDownloads.deviceName"),
                this.$t("ReportDownloads.device.zt"),
                this.$t("ReportDownloads.device.gl"),
                this.$t("ReportDownloads.device.dl"),
                this.$t("ReportDownloads.device.dy"),
                this.$t("ReportDownloads.device.glmkwd"),
                this.$t("ReportDownloads.device.jgwd")
              ];
              _this.reportParam.val = [
                "getdatetime",
                "deviceName",
                "deviceFlag",
                "ppv",
                "ipv",
                "vpv",
                "itemp",
                "temp"
              ];
              _this.reportParam.name =
                "DCDC--" +
                this.psName +
                this.$moment(this.valueson1).format("YYYY-MM-DD") +
                this.$moment().format(" HHmmss");
              break;
            case 3:
              //Bms
              _this.reportParam.title = [
                this.$t("ReportDownloads.getdatetime"),
                this.$t("ReportDownloads.deviceName"),
                this.$t("ReportDownloads.device.dy"),
                this.$t("ReportDownloads.device.cfddl"),
                this.$t("ReportDownloads.device.cfdgl"),
                "SOC（%）"
              ];
              _this.reportParam.val = [
                "getdatetime",
                "deviceName",
                "bv",
                "bcc",
                "bcp",
                "soc"
              ];
              _this.addBmsPara();
              _this.reportParam.name =
                "BMS--" +
                this.psName +
                this.$moment(this.valueson1).format("YYYY-MM-DD") +
                this.$moment().format(" HHmmss");
              break;
            case 4:
              //空调
              _this.reportParam.title = [
                this.$t("ReportDownloads.getdatetime"),
                this.$t("ReportDownloads.deviceName"),
                this.$t("ReportDownloads.device.zt"),
                this.$t("ReportDownloads.device.snwd")
              ];
              _this.reportParam.val = [
                "getdatetime",
                "deviceName",
                "deviceFlag",
                "indoortemp"
              ];
              _this.reportParam.name =
                this.$t("ReportDownloads.device.kt") +
                this.psName +
                this.$moment(this.valueson1).format("YYYY-MM-DD") +
                this.$moment().format(" HHmmss");
              break;
            default:
              _this.reportParam.isDis = false;
              break;
          }
        } else if (_this.$store.state.psTypeId == 3) {
          //削峰（AC）
          switch (_this.deviceTypeId) {
            case 1:
              //pcs
              var deviceInfo = JSON.parse(
                window.sessionStorage.getItem("deviceInfo")
              );
              var deviceModule = _this.deviceModuleBms;
              _this.reportParam.title = [
                this.$t("ReportDownloads.getdatetime"),
                this.$t("ReportDownloads.deviceName"),
                this.$t("ReportDownloads.device.jldl2") + "A(A)",
                this.$t("ReportDownloads.device.jldl2") + "B(A)",
                this.$t("ReportDownloads.device.jldl2") + "C(A)",
                this.$t("ReportDownloads.device.jldy2") + "A(V)",
                this.$t("ReportDownloads.device.jldy2") + "B(V)",
                this.$t("ReportDownloads.device.jldy2") + "C(V)",
                this.$t("ReportDownloads.device.jlgl"),
                this.$t("ReportDownloads.device.jlpl"),
                this.$t("ReportDownloads.device.glmkwd"),
                this.$t("ReportDownloads.device.jgwd"),
                this.$t("ReportDownloads.device.jrfd"),
                this.$t("ReportDownloads.device.ljfd"),
                this.$t("ReportDownloads.device.jrcd"),
                this.$t("ReportDownloads.device.ljcd"),
                this.$t("ReportDownloads.device.jrfd2"),
                this.$t("ReportDownloads.device.ljfd2")
              ];
              _this.reportParam.val = [
                "getdatetime",
                "deviceName",
                "rIac",
                "sIac",
                "tIac",
                "rVac",
                "sVac",
                "tVac",
                "pac",
                "rFac",
                "itemp",
                "temp",
                "dayPac",
                "totalPac",
                "dayInTotal",
                "inTotal",
                "dayOutTotal",
                "outTotal"
              ];
              var ipvs = [];
              var ppvs = [];
              var vpvs = [];
              var ipvsVal = [];
              var ppvsVal = [];
              var vpvsVal = [];
              for (var i = 1; i <= deviceModule; i++) {
                ipvs.push(
                  this.$t("ReportDownloads.device.zlglpv") + i + "（kW）"
                );
                ppvs.push(
                  this.$t("ReportDownloads.device.zldlpv") + i + "（A）"
                );
                vpvs.push(
                  this.$t("ReportDownloads.device.zldypv") + i + "（V）"
                );
                ipvsVal.push("ppvs-" + (i - 1));
                ppvsVal.push("ipvs-" + (i - 1));
                vpvsVal.push("vpvs-" + (i - 1));
              }
              _this.reportParam.title = _this.reportParam.title.concat(ipvs);
              _this.reportParam.title = _this.reportParam.title.concat(ppvs);
              _this.reportParam.title = _this.reportParam.title.concat(vpvs);

              _this.reportParam.val = _this.reportParam.val.concat(ipvsVal);
              _this.reportParam.val = _this.reportParam.val.concat(ppvsVal);
              _this.reportParam.val = _this.reportParam.val.concat(vpvsVal);

              _this.reportParam.name =
                "PCS--" +
                this.psName +
                this.$moment(this.valueson1).format("YYYY-MM-DD") +
                this.$moment().format(" HHmmss");
              break;
            case 3:
              //Bms
              _this.reportParam.title = [
                this.$t("ReportDownloads.getdatetime"),
                this.$t("ReportDownloads.deviceName"),
                this.$t("ReportDownloads.device.dy"),
                this.$t("ReportDownloads.device.cfddl"),
                this.$t("ReportDownloads.device.cfdgl"),
                "SOC（%）"
              ];
              _this.reportParam.val = [
                "getdatetime",
                "deviceName",
                "bv",
                "bcc",
                "bcp",
                "soc"
              ];
              _this.addBmsPara();
              _this.reportParam.name =
                "BMS--" +
                this.psName +
                this.$moment(this.valueson1).format("YYYY-MM-DD") +
                this.$moment().format(" HHmmss");
              break;
            case 4:
              //空调
              _this.reportParam.title = [
                this.$t("ReportDownloads.getdatetime"),
                this.$t("ReportDownloads.deviceName"),
                this.$t("ReportDownloads.device.zt"),
                this.$t("ReportDownloads.device.snwd")
              ];
              _this.reportParam.val = [
                "getdatetime",
                "deviceName",
                "deviceFlag",
                "indoortemp"
              ];
              _this.reportParam.name =
                this.$t("ReportDownloads.device.kt") +
                this.psName +
                this.$moment(this.valueson1).format("YYYY-MM-DD") +
                this.$moment().format(" HHmmss");
              break;
            default:
              _this.reportParam.isDis = false;
              break;
          }
        }
      } else if (_this.calendarArr.type == "month") {
        if (_this.$store.state.psTypeId == 1) {
          //1.pcs  2.dcdc  3.Bms   4.空调   5.电表
          switch (_this.deviceTypeId) {
            case 1:
              //pcs
              _this.reportParam.title = [
                this.$t("ReportDownloads.getdatetime"),
                this.$t("ReportDownloads.deviceName"),
                this.$t("ReportDownloads.device.cdl"),
                this.$t("ReportDownloads.device.fdl2")
              ];
              _this.reportParam.val = [
                "getdatetime",
                "deviceName",
                "inTotal",
                "outTotal"
              ];
              _this.reportParam.name =
                "PCS--" +
                this.psName +
                this.$moment(this.valueson1).format("YYYY-MM") +
                this.$moment().format(" HHmmss");
              break;
            case 2:
              //dcdc
              _this.reportParam.title = [
                this.$t("ReportDownloads.getdatetime"),
                this.$t("ReportDownloads.deviceName"),
                this.$t("ReportDownloads.device.gffdl")
              ];
              _this.reportParam.val = ["getdatetime", "deviceName", "pvTotal"];
              _this.reportParam.name =
                "DCDC--" +
                this.psName +
                this.$moment(this.valueson1).format("YYYY-MM") +
                this.$moment().format(" HHmmss");
              break;
            case 3:
              //Bms
              _this.reportParam.title = [
                this.$t("ReportDownloads.getdatetime"),
                this.$t("ReportDownloads.deviceName"),
                this.$t("ReportDownloads.device.zgwd"),
                this.$t("ReportDownloads.device.zdwd"),
                this.$t("ReportDownloads.device.zgdy"),
                this.$t("ReportDownloads.device.zddy")
              ];
              _this.reportParam.val = [
                "getdatetime",
                "deviceName",
                "btmax",
                "btmin",
                "bvmax",
                "bvmin"
              ];
              _this.reportParam.name =
                "BMS--" +
                this.psName +
                this.$moment(this.valueson1).format("YYYY-MM") +
                this.$moment().format(" HHmmss");
              break;
            case 4:
              //空调
              _this.reportParam.title = [
                this.$t("ReportDownloads.getdatetime"),
                this.$t("ReportDownloads.deviceName"),
                this.$t("ReportDownloads.device.zgwd"),
                this.$t("ReportDownloads.device.zdwd")
              ];
              _this.reportParam.val = [
                "getdatetime",
                "deviceName",
                "maxindoortemp",
                "minindoortemp"
              ];
              _this.reportParam.name =
                this.$t("ReportDownloads.device.kt") +
                this.psName +
                this.$moment(this.valueson1).format("YYYY-MM") +
                this.$moment().format(" HHmmss");
              break;
            default:
              _this.reportParam.isDis = false;
              break;
          }
        } else if (_this.$store.state.psTypeId == 2) {
          //过激载
          switch (_this.deviceTypeId) {
            case 1:
              //pcs
              _this.reportParam.title = [
                this.$t("ReportDownloads.getdatetime"),
                this.$t("ReportDownloads.deviceName"),
                this.$t("ReportDownloads.device.fdl")
              ];
              _this.reportParam.val = ["getdatetime", "deviceName", "dayPac"];
              _this.reportParam.name =
                "PCS--" +
                this.psName +
                this.$moment(this.valueson1).format("YYYY-MM") +
                this.$moment().format(" HHmmss");
              break;
            case 2:
              //dcdc
              _this.reportParam.title = [
                this.$t("ReportDownloads.getdatetime"),
                this.$t("ReportDownloads.deviceName"),
                this.$t("ReportDownloads.device.gffdl")
              ];
              _this.reportParam.val = ["getdatetime", "deviceName", "pvTotal"];
              _this.reportParam.name =
                "DCDC--" +
                this.psName +
                this.$moment(this.valueson1).format("YYYY-MM") +
                this.$moment().format(" HHmmss");
              break;
            case 3:
              //Bms
              _this.reportParam.title = [
                this.$t("ReportDownloads.getdatetime"),
                this.$t("ReportDownloads.deviceName"),
                this.$t("ReportDownloads.device.zgwd"),
                this.$t("ReportDownloads.device.zdwd"),
                this.$t("ReportDownloads.device.zgdy"),
                this.$t("ReportDownloads.device.zddy")
              ];
              _this.reportParam.val = [
                "getdatetime",
                "deviceName",
                "btmax",
                "btmin",
                "bvmax",
                "bvmin"
              ];
              _this.reportParam.name =
                "BMS--" +
                this.psName +
                this.$moment(this.valueson1).format("YYYY-MM") +
                this.$moment().format(" HHmmss");
              break;
            case 4:
              //空调
              _this.reportParam.title = [
                this.$t("ReportDownloads.getdatetime"),
                this.$t("ReportDownloads.deviceName"),
                this.$t("ReportDownloads.device.zgwd"),
                this.$t("ReportDownloads.device.zdwd")
              ];
              _this.reportParam.val = [
                "getdatetime",
                "deviceName",
                "maxindoortemp",
                "minindoortemp"
              ];
              _this.reportParam.name =
                this.$t("ReportDownloads.device.kt") +
                this.psName +
                this.$moment(this.valueson1).format("YYYY-MM") +
                this.$moment().format(" HHmmss");
              break;
            default:
              _this.reportParam.isDis = false;
              break;
          }
        } else if (_this.$store.state.psTypeId == 3) {
          //削峰（AC）
          //过激载
          switch (_this.deviceTypeId) {
            case 1:
              //pcs
              _this.reportParam.title = [
                this.$t("ReportDownloads.getdatetime"),
                this.$t("ReportDownloads.deviceName"),
                this.$t("ReportDownloads.device.cdl"),
                this.$t("ReportDownloads.device.fdl2")
              ];
              _this.reportParam.val = [
                "getdatetime",
                "deviceName",
                "inTotal",
                "outTotal"
              ];
              _this.reportParam.name =
                "PCS--" +
                this.psName +
                this.$moment(this.valueson1).format("YYYY-MM") +
                this.$moment().format(" HHmmss");
              break;
            case 3:
              //Bms
              _this.reportParam.title = [
                this.$t("ReportDownloads.getdatetime"),
                this.$t("ReportDownloads.deviceName"),
                this.$t("ReportDownloads.device.zgwd"),
                this.$t("ReportDownloads.device.zdwd"),
                this.$t("ReportDownloads.device.zgdy"),
                this.$t("ReportDownloads.device.zddy")
              ];
              _this.reportParam.val = [
                "getdatetime",
                "deviceName",
                "btmax",
                "btmin",
                "bvmax",
                "bvmin"
              ];
              _this.reportParam.name =
                "BMS--" +
                this.psName +
                this.$moment(this.valueson1).format("YYYY-MM") +
                this.$moment().format(" HHmmss");
              break;
            case 4:
              //空调
              _this.reportParam.title = [
                this.$t("ReportDownloads.getdatetime"),
                this.$t("ReportDownloads.deviceName"),
                this.$t("ReportDownloads.device.zgwd"),
                this.$t("ReportDownloads.device.zdwd")
              ];
              _this.reportParam.val = [
                "getdatetime",
                "deviceName",
                "maxindoortemp",
                "minindoortemp"
              ];
              _this.reportParam.name =
                this.$t("ReportDownloads.device.kt") +
                this.psName +
                this.$moment(this.valueson1).format("YYYY-MM") +
                this.$moment().format(" HHmmss");
              break;
            default:
              _this.reportParam.isDis = false;
              break;
          }
        }
      }
    },
    //电站报表下载
    formatJson(filterVal, jsonData) {
      var _this = this;
      return jsonData.map(v =>
        filterVal.map(j => {
          if (j === "getdatetime") {
            if (_this.calendarArr.type == "month") {
              v[j] = v[j] != null ? v[j].split(" ")[0] : null;
              return v[j] != null ? v[j].split("-")[2] : "-";
            } else if (
              j === "inTotal" ||
              j === "outTotal" ||
              j === "gridEngery" ||
              j === "sendEngery"
            ) {
              return v[j] != null ? Math.abs(v[j]) : "";
            } else {
              return v[j];
            }
          } else if (j === "deviceFlag") {
            switch (v[j]) {
              case 0:
                return this.$t("history.ari.normal"); //"正常"
                break;
              case 1:
                return this.$t("history.ari.offLine"); //"离线"
                break;
              case 3:
                return this.$t("home.shutDown"); //"停机"
                break;
              case 4:
                return this.$t("history.ari.fault"); //"故障"
                break;
              default:
                return "-";
                break;
            }
          } else if (
            j.indexOf("mtMaxList") != -1 ||
            j.indexOf("mtMinList") != -1 ||
            j.indexOf("mvMaxList") != -1 ||
            j.indexOf("mvMinList") != -1 ||
            j.indexOf("msocList") != -1
          ) {
            return v[j.split("-")[0]][j.split("-")[1]];
          } else if (
            j.indexOf("ipvs") != -1 ||
            j.indexOf("ppvs") != -1 ||
            j.indexOf("vpvs") != -1
          ) {
            return v[j.split("-")[0]][j.split("-")[1]];
          } else {
            return v[j];
          }
        })
      );
    },
    downClick() {
      this.reportParam.loading = true;
      if (this.reportType == "1") {
        this.getPsReport();
      } else {
        this.getDeviceReport();
      }
    },
    downReport() {
      var _this = this;
      if(!_this.reportParam.isDis){
          swal({
            //提示的标题
            title: _this.$t("ReportDownloads.noData"), //"保存成功",
            //提示的文本
            text: "", //'',
            //类型：警告，还有success,error和info
            type: "success",
            //显示取消按钮
            showCancelButton: false,
            //确认按钮内文本
            confirmButtonText: _this.$t("master.determine") //'确定'
          });
          _this.reportParam.loading = false;
          return
      }

      import("@/assets/vendor/excel/Export2Excel").then(excel => {
        const tHeader = _this.reportParam.title;
        const filterVal = _this.reportParam.val;
        const list = _this.reportParam.result;
        if (list != null && list.length > 0) {
          const data = _this.formatJson(filterVal, list);
          if (_this.calendarArr.type == "month" && _this.deviceTypeId != 4 && _this.deviceTypeId != 3){
                var total= JSON.parse(JSON.stringify(data[0]))
                total[0]=this.$t("ReportDownloads.total")
                var temp=0;
                if (this.reportType == "1") {
                    temp=0;
                }else{
                  total[1]="";
                  temp=1;
                }
                $(data).each(function(i,val){
                    if(i>0){
                      $(val).each(function(x,t){
                        if(x>temp){
                            total[x]+=t
                        }
                      })
                    }
                })
                data.push(total);

          }

          excel.export_json_to_excel({
            header: tHeader,
            data,
            filename: _this.reportParam.name,
            bookType: "csv"
          });
        } else {
          swal({
            //提示的标题
            title: _this.$t("ReportDownloads.noData"), //"保存成功",
            //提示的文本
            text: "", //'',
            //类型：警告，还有success,error和info
            type: "success",
            //显示取消按钮
            showCancelButton: false,
            //确认按钮内文本
            confirmButtonText: _this.$t("master.determine") //'确定'
          });
        }
        _this.reportParam.loading = false;
      });
    },
    async getPsReport() {
      var _this = this;
      if (
        this.$store.state.psId != undefined &&
        this.$store.state.psId != null &&
        this.$store.state.psId != ""
      ) {
        let url = "";
        if (_this.calendarArr.type == "date") {
          url =
            "/service-device/pssys/getDeviceDayPacsByPsid?psId=" +
            _this.$store.state.psId +
            "&searchDate=" +
            _this.valueson1 +
            "&psTypeId=" +
            _this.$store.state.psTypeId;
        } else if (_this.calendarArr.type == "month") {
          url =
            "/service-device/pssys/getDeviceMonthAmountByPsid?psId=" +
            _this.$store.state.psId +
            "&searchDate=" +
            _this.valueson1 +
            "&psTypeId=" +
            _this.$store.state.psTypeId;
        }
        const { data: res } = await this.$http.get(url, {});

        if (res.status == 200) {
          for (let i = 0; i < res.data.length; i++) {
            let getdatetime = res.data[i]["getdatetime"];
            if (
              getdatetime != undefined &&
              getdatetime != null &&
              getdatetime.indexOf(" ") >= 0
            ) {
              let gettime = getdatetime.split(" ")[1];
              let gettimeArr = gettime.split(":");
              res.data[i]["getdatetime"] =
                getdatetime.split(" ")[0] +
                " " +
                gettimeArr[0] +
                ":" +
                gettimeArr[1] +
                ":00";
            }
          }
          _this.formatReportPs(res.data); //电站报表赋值
          _this.downReport();
        } else if (res.status == 564) {
          swal({
            //提示的标题
            title: _this.$t("interfaceMsg.600"),
            //提示的文本
            text: _this.$t("interfaceMsg.564"),
            //类型：警告，还有success,error和info
            type: "warning",
            //显示取消按钮
            showCancelButton: false,
            //确认按钮内文本
            confirmButtonText: _this.$t("login.msg.determine") //'确定'
          });
          this.reportParam.loading = false;
        }
      }
    },
    async getDeviceReport() {
      var _this = this;
      if (
        this.$store.state.psId != undefined &&
        this.$store.state.psId != null &&
        this.$store.state.psId != ""
      ) {
        let url = "";
        if (_this.calendarArr.type == "date") {
          url =
            "/service-device/pssys/getDeviceDetailsByPsidTypeid?psId=" +
            this.$store.state.psId +
            "&deviceTypeId=" +
            this.deviceTypeId +
            "&searchDate=" +
            this.valueson1;
        } else if (_this.calendarArr.type == "month") {
          if (_this.deviceTypeId == 5) {
            swal({
              //提示的标题
              title: _this.$t("ReportDownloads.noData"), //"保存成功",
              //提示的文本
              text: "", //'',
              //类型：警告，还有success,error和info
              type: "success",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: _this.$t("master.determine") //'确定'
            });
            this.reportParam.loading = false;
            return false;
          }
          url =
            "/service-device/pssys/getDeviceDaysByPsidTypeid?psId=" +
            this.$store.state.psId +
            "&deviceTypeId=" +
            this.deviceTypeId +
            "&searchDate=" +
            this.valueson1;
        }
        const { data: res } = await this.$http.get(url, {});

        if (res.status == 200) {
          for (let i = 0; i < res.data.length; i++) {
            let getdatetime = res.data[i]["getdatetime"];
            if (
              getdatetime != undefined &&
              getdatetime != null &&
              getdatetime.indexOf(" ") >= 0
            ) {
              let gettime = getdatetime.split(" ")[1];
              let gettimeArr = gettime.split(":");
              res.data[i]["getdatetime"] =
                getdatetime.split(" ")[0] +
                " " +
                gettimeArr[0] +
                ":" +
                gettimeArr[1] +
                ":00";
            }
          }
          _this.formatReportDevice(res.data); //电站报表赋值
          _this.downReport();
        } else if (res.status == 564) {
          swal({
            //提示的标题
            title: _this.$t("interfaceMsg.600"),
            //提示的文本
            text: _this.$t("interfaceMsg.564"),
            //类型：警告，还有success,error和info
            type: "warning",
            //显示取消按钮
            showCancelButton: false,
            //确认按钮内文本
            confirmButtonText: _this.$t("login.msg.determine") //'确定'
          });
          this.reportParam.loading = false;
        }
      }
    },
    changeDeviceTypeId(deviceTypeId) {
      this.deviceTypeId = deviceTypeId;
    },
    //切换月和日的日历控件
    chageTypeCalendar() {
      if (this.calendarArr.format == "yyyy-MM-dd") {
        this.calendarArr.format = "yyyy-MM";
        this.calendarArr.type = "month";
        this.primaryArr.days.primaryDay = "";
        this.primaryArr.month.primaryMonth = "primary";
        this.valueson1 = this.formatDate(
          parseInt(new Date().getFullYear()),
          parseInt(new Date().getMonth() + 1),
          1
        );
      } else {
        this.calendarArr.format = "yyyy-MM-dd";
        this.calendarArr.type = "date";
        this.primaryArr.days.primaryDay = "primary";
        this.primaryArr.month.primaryMonth = "";
        this.valueson1 = this.formatDate(
          parseInt(new Date().getFullYear()),
          parseInt(new Date().getMonth() + 1),
          parseInt(new Date().getDate())
        );
      }
      this.getParamsButton();
      this.initChange();
      this.reset = Math.floor(Math.random() * 999999);
      this.showLoading = false;
      this.IsDisabled = false;
    },
    handleDevShowLoading(flag) {
      this.isDevsShow = flag;
    },
    handleDataLoading(flag) {
      this.IsDisabled = flag;
    },

    //切换电站、切换日期、切换日月重置
    initChange() {
      this.showLoading = false;
      this.IsDisabled = false;
      this.value1 = [];
      this.params = [];
      this.checkDeviceTypeIds = [];
      this.initSon = Math.floor(Math.random() * 999999);
      this.callnum = Math.floor(Math.random() * 999999);
      // this.initFirstItem();
    },
    initParams() {
      for (let i = 0; i < this.options.length; i++) {
        this.visibleValue[i] = this.options[i]["value"];
      }
    },
    //更换日期时查询数据
    dateSreach() {
      this.showLoading = false;
      if (this.checkDeviceTypeId != "loading") {
        this.initChange();
        this.reset = Math.floor(Math.random() * 999999);
      }
    },
    handlePsIdChange(psBuilddate, psId, psName) {
      this.psName = psName;
      this.getDeviceTypeByPsId(psId);
    },
    formatDate: function(year, month, day) {
      var y = year;
      var m = month;
      if (m < 10) m = "0" + m;
      var d = day;
      if (d < 10) d = "0" + d;
      return y + "-" + m + "-" + d;
    },
    async getDeviceTypeByPsId(psId) {
      var _this = this;
      this.deviceTypeList = [];
      try {
        var urlstr = "/service-psinfo/psinfo/getDeviceTypeByPsId?psId=" + psId;
        const { data: res } = await this.$http.get(urlstr, {});
        if (res.status == 200) {
          _this.deviceTypeList = res.data;
          if (_this.deviceTypeList != null) {
            _this.deviceTypeId = res.data[0].deviceTypeId;
          }
        }
      } catch (err) {}
    }
  }
};
</script>

<style>
.para_window {
  display: block;
  margin: auto;
  position: absolute;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
